.sub_top_image {
    width: 100%;
    max-width: 1920px;
    height: 450px;
    margin: 0 auto;
}
.sub_top_image .text_content {
    padding-top: 200px;
    color: white;
    text-align: center;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 40px;
}