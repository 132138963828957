.sub_top_image_download .text_content {
    padding-top: 200px;
    color: white;
    text-align: center;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 40px;
}
.sub_top_image_download .download_client_button {
    text-align: center;
    padding-top: 20px;
}