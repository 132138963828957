.notice_title {
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 30px;
    color: #525252;
    text-decoration: none;
}

.notice_title a {
    text-decoration: none;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 30px;
    color: #525252;
}

#noticetext {
    font-family: 'Noto Serif', sans-serif;
    font-size: 18px;
    color: #7A7A7A;
}

#noticetext2 {
    font-family: 'Noto Serif', sans-serif;
    font-size: 18px;
    color: #7A7A7A;
    text-decoration: none;
}