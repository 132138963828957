
@media (min-width: 300px) {
    #playnowdiv {
        padding-top: 17px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column!important;
        align-items: center;
    }
}

@media (min-width: 1200px) {
    #playnowdiv {
        padding-top: 37px;
        padding-bottom: 0;
        display: inherit;
    }
}

@media (min-width: 300px) {
    #playnow {
        padding-top: 80px;
        padding-bottom: 60px;
        width: 100%;
        height: 100%;
        text-align: center;
        background: rgba(255,255,255,0.75);
    }
}

@media (min-width: 1200px) {
    #playnow {
        padding-top: 160px;
        padding-bottom: 160px;
        width: 100%;
        height: 100%;
        text-align: left;
        /*background: url("../../assets/img/feature2.png") top / cover no-repeat;*/
    }
}

@media (min-width: 300px) {
    #heading-playnow {
        margin-top: 0px;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    #heading-playnow {
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 10px;
        margin-bottom: 20px;
    }
}

#heading-playnow {
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 55px;
    color: #8A5136;
}