.sub_content .login_box {
    width: 70%;
    max-width: 600px;
    min-width: 275px;
    height: 514px;
    border: 2px solid #A4A4A4;
    margin: 0 auto;
    background-color: #FCFAFB;
}
.login_box .logo {
    width: 100%;
    /*height: 65px;*/
    padding-top: 40px;
    padding-bottom: 30px;
    text-align: center;
}

.login_box .input_container {
    width: 100%;
    /*height: 70px;*/
    text-align: center;
    padding-bottom: 30px
}

.login_box input[type=button] {
    background-color: #909090;
    width:90%;
    height: 60px;
    border: 1px solid black;
    border-radius: 20px;
    color: white;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 26px;
}