
.sub_content_download {
    width: 100%;
    max-width: 1920px;
    min-height: 950px;
    background-color: white;
    padding: 5% 15%;
    margin: 0 auto;
}

.sub_content_download .download_title {
    width: 100%;
    height: 40px;
    font-size: 22px;
    padding-bottom: 40px;
}
.sub_content_download table {
    width: 100%;
    height: 360px;
    border-top: black 1px solid;
    border-left: black 1px solid;
    border-spacing: 0;
    margin: 0 auto;
}
.sub_content_download table tr {
    height: 60px;
}
.sub_content_download table th {
    background-color: #D2CECF;
    border-bottom: black 1px solid;
    border-right: black 1px solid;
    text-align: center;
}
.sub_content_download table td {
    border-bottom: black 1px solid;
    border-right: black 1px solid;
    text-align: center;
}
.sub_content_download .desc {
    padding-top: 30px;
    font-size: 22px;
}

.sub_content_download .driver_title {
    padding-top: 200px;
    font-size: 22px;
}
.sub_content_download .driver_buttons {
    padding-top: 20px;
    margin: 0 auto;
}
.sub_content_download .driver_buttons .item {
    width: 15%;
    height: 150px;
    float: left;
}

.sub_content_download .driver_buttons .item .driver_img {
    text-align: center;
    padding-bottom: 10px;
}
.sub_content_download .driver_buttons .item .install_text {
    text-align: center;
    height: 30px;
    width: 160px;
    font-size: 22px;
    margin-left: 20px;
    background-color: #858585;
    margin: 0 auto;
}
.sub_content_download .driver_buttons .space {
    width: 15%;
    height: 150px;
    float: left;
}

@media (max-width: 690px) {
    .sub_content_download .driver_buttons .item {
        width: 80%;
        float: none;
    }
}
@media (max-width: 690px) {
    .sub_content_download .driver_buttons .space {
        width: 15%;
    }
}