.carousel-caption {
    left: 10%;
    right: auto;
    top: 35%;
    max-width: 1100px;
    padding:5px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 2.5vw;
}

.top_carousel {
    margin-top: 66px;
}

@media screen and (min-width: 1900px) {
    .carousel-caption {
        font-size: 48px;
        top: 40%;
    }
}

@media screen and (max-width: 768px) {
    .top_carousel {
        margin-top: 66px;
    }
}
