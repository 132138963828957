.t_btn {
    width: 148px;
    height: 150px;
    float: left;
    padding-right: 1px;
}

.guide3 .top_sub_title {
    padding-top: 140px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 16px;
    color: #A08040;
    width: 100%;
    height: 20px;
}
.guide3 .top_big_title {
    padding-top: 30px;
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 36px;
    color: #DCDADB;
    width: 100%;
    height: 40px;
}
.job {
    color: white;
    font-size: 22px;
}

.job_sub {
    color: #A57840;
    font-size: 12px;
}

.job_desc {
    color: #E5E5E5;
    font-size: 14px;
    padding-top: 10px;
    width: 400px;
}
.job_image {
    width: 480px;
    height: 335px;
}