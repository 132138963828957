.notice_menu_item {
    width: 265px;
    height: 49px;
    float: left;
    padding-top: 10px;
    text-align: center;
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 22px;
}
.notice_menu_item:hover {
    background-color: #858585;
    color: white;
}
