.detail_title {
    padding-top: 60px;
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 22px;
}

.detail_content {
    min-height: 500px;
}

.side_list {
    padding-top: 30px;
}

.side_list .content {
    padding-top: 10px;
}

.row .date {
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 14px;
    color: #706F70;
    padding-top: 10px;
    padding-bottom: 10px;
}
.list_back_btn {
    padding: 0 30px 0 30px;
    height: 40px;
    width: 200px;
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
}