@media (min-width: 300px) {
    .maindiv {
        padding-left: 0%;
        padding-right: 0%;
    }
}

@media (min-width: 1200px) {
    .maindiv {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media (min-width: 1600px) {
    .maindiv {
        padding-left: 8%;
        padding-right: 8%;
    }
}
@media (min-width: 300px) {
    #overview-section {
        background: rgba(0,0,0,0.4);
        padding-top: 150px;
        padding-bottom: 150px;
    }
}

@media (min-width: 1200px) {
    #overview-section {
        background: rgba(0,0,0,0.4);
        padding-top: 240px;
        padding-bottom: 240px;
    }
}

@media (min-width: 300px) {
    #heading-overview {
        font-family: 'HeirofLightBold', sans-serif;
        font-size: 48px;
        color: white;
        margin-bottom: 60px;
        font-weight: 600;
    }
}

@media (min-width: 1200px) {
    #heading-overview {
        font-family: 'HeirofLightBold', sans-serif;
        font-size: 48px;
        color: white;
        margin-bottom: 60px;
        font-weight: 600;
    }
}

.video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
    opacity: 0.8;
}

#heading-playnow {
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 55px;
    color: #8A5136;
}