.item {
    color: white;
    font-family: 'Noto Serif', sans-serif;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.item:hover {
    color: #cda24f;
}
.dropdown-menu {
    /*right: 0;*/
    /*left: auto;*/
    width: 300px;
}
.playnow {
    color: #E5AC5E;
    font-family: 'Noto Serif', sans-serif;
    font-size: 20px;
    text-decoration: none;
}

#btn_login {
    width: 100%;
    height: 70px;
    border-style: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    background: rgb(65,52,39);
    letter-spacing: 0;
    line-height: 1;
}
#btn_create {
    width: 100%;
    height: 70px;
    border-style: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    background: rgb(65,52,39);
    letter-spacing: 0;
    line-height: 1;
}


@media (max-width: 768px) {
    .navi_container {
        justify-content: flex-start;
    }

    .playnow {
        font-size: 16px;
    }
}