.sub_content {
    max-width: 1920px;
    min-height: 950px;
    background-color: white;
    padding: 50px 0;
    margin: 0 auto;
}
.sub_content .center_content {
    width: 100%;
    /*height: 400px;*/
    padding-top: 40px;
    text-align: center;
    /*font-size: 38px*/
    font-size: 2rem;
}

.center_content .black_button {
    margin: 0 auto;
    width: 265px;
    background-color: black;
    border: 3px solid #8A9090;
    font-size: 22px;
    color:white;
    font-weight: bold;
    padding-top: 10px;
    height: 60px;
}