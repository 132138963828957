.guide1 {
}
.guide1 .guide1_sub_title {
    padding-top: 200px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 16px;
    color: #AF7942;
}
.guide1 .guide1_title {
    width: 600px;
    height: 100px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 24px;
    color: #ADADAD;
}
.guide1 .guide1_content {
    width: 600px;
    height: 200px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 16px;
    color: #ADADAD;
}
.guide1 .guide1_video {
    width: 650px;
    height: 400px;
    padding-top: 20px;
}

@media (min-width: 300px) {
    .guide1 {
        padding-left: 5%;
    }
}

@media (min-width: 1200px) {
    .guide1 {
        padding-left: 10%;
    }
}

@media (min-width: 1600px) {
    .guide1 {
        padding-left: 15%;
    }
}
@media (min-width: 1900px) {
    .guide1 {
        padding-left: 20%;
    }
}