@media (min-width: 300px) {
    .maindiv {
        padding-left: 0%;
        padding-right: 0%;
    }
}

@media (min-width: 1200px) {
    .maindiv {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media (min-width: 1600px) {
    .maindiv {
        padding-left: 8%;
        padding-right: 8%;
    }
}

@media (min-width: 300px) {
    #footer-div {
        padding-top: 40px;
        padding-bottom: 40px;
        display: inherit;
    }
}

@media (min-width: 1200px) {
    #footer-div {
        padding-top: 40px;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: 300px) {
    #footer-link {
        color: rgb(103,103,103);
        font-size: 18px;
        font-family: 'HeirofLightRegular', sans-serif;
        margin-right: 40px;
        text-decoration: none;
        margin-left: 40px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    #footer-link {
        color: rgb(103,103,103);
        font-size: 18px;
        font-family: 'HeirofLightRegular', sans-serif;
        margin-right: 40px;
        text-decoration: none;
        margin-left: 40px;
        display: initial;
    }
}

#footer-link:hover {
    color: rgb(221,221,221);
}


.dropdown-item {
    color: rgb(103,103,103);
    font-size: 18px;
    font-family: 'HeirofLightRegular', sans-serif;
    text-decoration: none;
}

.drop_item_language {
    color: rgb(89,89,89);
}

.drop_item_language:hover {
    color: white;
    background-color: #525252;
}