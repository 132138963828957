.notice_pg_navi {
    width: 400px;
    height: 40px;
    padding:  30px;
    margin: 0 auto;
    text-align: center;
}

.notice_pg_navi span {
    width: 20px;
    height: 20px;
    padding: 5px;
}
.notice_search_box {
    width: 550px;
    height: 60px;
    margin: 0 auto;
}