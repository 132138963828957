.list_title {
    padding-top: 20px;
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 22px;
}

.list_summary {
    padding-top: 20px;
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 18px;
}
.list_date {
    font-family: 'HeirofLightRegular', sans-serif;
    font-size: 16px;
}
.notice_under_line {
    border-bottom: 1px solid #e5e5e5;
    padding-top: 20px;
    padding-bottom: 20px;
}