
.guide2 .guide2_sub_title {
    width: 100%;
    height: 40px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 16px;
    color: #F1DCD9;
    text-align: center;
    margin-top: 40px;
}
.guide2 .guide2_title {
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 40px;
    color: #D3D2D2;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.guide2 .chapters {
    /*width: 540px;*/
    height: 50px;
    padding-top: 50px;
    /*margin: 0 auto;*/
}
.guide2 .chapters .chapter {
    /*width: 180px;*/
    /*float:left;*/
    color: white;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 18px;
    text-align: center;
}
.guide2 .chapters .chapter:hover {
    color: #E4A653;
}
.guide2 .book_title {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    color: #483418;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 22px;
}
.guide2 .book_content {
    padding-top: 100px;
    /*width: 740px;*/
    /*height: 240px;*/
    margin: 0 auto;
    padding-bottom: 20px;
    max-width: 800px;
}

@media (max-width: 770px) {
    .guide2 .chapters {
        padding-top: 0;
    }
}