.title {
    top: 15%;
    width: 100%;
    text-align: center;
    padding:5px;
    font-family: 'HeirofLightBold', sans-serif;
    font-size: 3vw;
    position: absolute;
    color: #fff;
}

@media screen and (min-width: 1900px) {
    .title {
        font-size: 48px;
    }
}
@media screen and (max-width: 576px) {
    .title {
        font-size: 18px;
    }
}

